<template>
    <aside
        class="aside-link"
    >
        <ul
            class="aside-link__list"
        >
            <li
                v-for="(link, index) in LINKS"
                :key="index"
                class="aside-link__item"
            >
                <div
                    v-if="link.name === 'program'"
                    class="download-pdf"
                    @click="openPdf"
                >
                    {{ link.title }}
                </div>
                <router-link
                    v-else
                    :to="{ name: link.name }"
                >
                    {{ link.title }}
                </router-link>
            </li>
        </ul>
    </aside>
</template>

<script
    setup
    lang="ts"
>

import {LINKS} from "@/constants";

const openPdf = () => {
    window.open('/documents/program.pdf', "_blank")
};
</script>
