<template>
    <div
        class="container"
    >
        <header
            class="header-default"
        >
            <img
                class="header-default__logo"
                alt="Логотип"
                src="../assets/logo.png"
            >
            
            <BurgerButton
                v-model="openMenu"
            />
        </header>
        
        <Teleport
            to="body"
        >
            <TransitionBlock>
                <nav
                    v-if="openMenu"
                    class="header-default__nav"
                >
                    <ul
                        class="header-default__list"
                    >
                        <li
                            v-for="(link, index) in LINKS"
                            :key="index"
                            class="header-default__item"
                        >
                            <div
                                v-if="link.name === 'program'"
                                class="download-pdf"
                                @click="openPdf"
                            >
                                {{ link.title }}
                            </div>
                            <router-link
                                v-else
                                :to="{ name: link.name }"
                                @click.native="openMenu = false"
                            >
                                {{ link.title }}
                            </router-link>
                        </li>
                    </ul>
                </nav>
            </TransitionBlock>
        </Teleport>
    </div>
</template>

<script
    setup
    lang="ts"
>
import {ref} from "vue";
import BurgerButton from "@/components/BurgerButton.vue";
import TransitionBlock from "@/components/TransitionBlock.vue";
import {LINKS} from "@/constants";

const openMenu = ref<boolean>(false);

const openPdf = () => {
    window.open('/documents/program.pdf', "_blank")
};
</script>
