export const LINKS: { title: string, name: string }[] = [
    {
        title: "О нас",
        name: "home"
    },
    {
        title: "Программа",
        name: "program"
    },
    {
        title: "Контакты",
        name: "contact"
    },
]