import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProgramView from "@/views/ProgramView.vue";
import ContactView from "@/views/ContactView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/program',
        name: 'program',
        component: ProgramView,
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView,
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
