import { computed, getCurrentInstance, WritableComputedRef } from 'vue';

export function useVModel<T>(props: { [key: string]: T }, propName: string): WritableComputedRef<T> {
    const vm = getCurrentInstance()?.proxy;

    return computed({
        get() {
            return props[propName];
        },
        set(newValue) {
            if (vm) {
                vm.$emit(`update:${propName}`, newValue);
            }
        },
    }) as WritableComputedRef<T>;
}
