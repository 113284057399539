<template>
    <span></span>
</template>

<script setup lang="ts">
import { useHead } from "@vueuse/head";

const props = defineProps({
    title: { type: String, default: '' }
});

useHead({
    title: `${props.title} | Подсолнухи`,
    meta: [
        {
            name: 'description',
            content: 'Подсолнухи - информация, выращивание, уход и интересные факты.'
        },
        {
            name: 'keywords',
            content: 'подсолнухи, выращивание подсолнухов, уход за подсолнухами, семена подсолнухов, сорта подсолнухов'
        },
        {
            property: 'og:title',
            content: `${props.title} | Подсолнухи`
        },
        {
            property: 'og:description',
            content: 'Все о подсолнухах: выращивание, уход, интересные факты и советы.'
        },
        {
            property: 'og:type',
            content: 'website'
        },
        {
            property: 'og:url',
            content: window.location.href
        },
        {
            property: 'og:image',
            content: '/public/icons/logo/apple-icon-180x180.png'
        },
        {
            name: 'twitter:card',
            content: 'summary_large_image'
        },
        {
            name: 'twitter:title',
            content: `${props.title} | Подсолнухи`
        },
        {
            name: 'twitter:description',
            content: 'Все о подсолнухах: выращивание, уход, интересные факты и советы.'
        },
        {
            name: 'twitter:image',
            content: '/public/icons/logo/apple-icon-180x180.png'
        }
    ],
    link: [
        { rel: 'apple-touch-icon', sizes: '120x120', href: '/public/icons/logo/apple-icon-120x120.png' },
        { rel: 'apple-touch-icon', sizes: '152x152', href: '/public/icons/logo/apple-icon-152x152.png' },
        { rel: 'apple-touch-icon', sizes: '167x167', href: '/public/icons/logo/apple-icon-167x167.png' },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/public/icons/logo/apple-icon-180x180.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/public/icons/logo/favicon-16x16.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/public/icons/logo/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '96x96', href: '/public/icons/logo/favicon-96x96.png' },
        { rel: 'icon', type: 'image/png', sizes: '128x128', href: '/public/icons/logo/icon-128x128.png' },
        { rel: 'icon', type: 'image/png', sizes: '192x192', href: '/public/icons/logo/icon-192x192.png' },
        { rel: 'icon', type: 'image/png', sizes: '256x256', href: '/public/icons/logo/icon-256x256.png' },
        { rel: 'icon', type: 'image/png', sizes: '384x384', href: '/public/icons/logo/icon-384x384.png' },
        { rel: 'icon', type: 'image/png', sizes: '512x512', href: '/public/icons/logo/icon-512x512.png' },
        { rel: 'shortcut icon', href: '/public/favicon.ico' },
        { rel: 'manifest', href: '/public/manifest.json' }
    ]
});
</script>
