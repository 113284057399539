<template>
    <div
        class="documents-row"
    >
        Программа дополнительного образования
        
        <div
            class="documents-row__actions"
        >
            
            <a
                href="/documents/1.pdf"
                target="_blank"
            >
                <svg
                    class="cursor-pointer"
                    width="45"
                    height="45"
                    viewBox="0 0 45 45"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="0.48913" y="0.48913" width="44.0217" height="44.0217" rx="22.0109" stroke="black"
                          stroke-opacity="0.2" stroke-width="0.978261"/>
                    <path
                        d="M22.0006 26.1506C23.7403 26.1506 25.1506 24.7403 25.1506 23.0006C25.1506 21.2609 23.7403 19.8506 22.0006 19.8506C20.2609 19.8506 18.8506 21.2609 18.8506 23.0006C18.8506 24.7403 20.2609 26.1506 22.0006 26.1506Z"
                        fill="black"/>
                    <path
                        d="M33.5595 22.1436C32.2572 20.1296 30.5675 18.4187 28.6736 17.1956C26.5783 15.8411 24.265 15.125 21.9842 15.125C19.8915 15.125 17.8336 15.723 15.8678 16.9023C13.8632 18.1047 12.047 19.8613 10.4695 22.1229C10.2914 22.3785 10.1934 22.6813 10.1878 22.9927C10.1821 23.3042 10.2693 23.6103 10.438 23.8722C11.7379 25.9064 13.4108 27.6197 15.2752 28.826C17.3744 30.1859 19.6336 30.875 21.9842 30.875C24.2833 30.875 26.6015 30.1648 28.6878 28.8216C30.5808 27.6024 32.267 25.8852 33.5644 23.8544C33.7274 23.5987 33.8135 23.3015 33.8127 22.9983C33.8118 22.695 33.7239 22.3984 33.5595 22.1436V22.1436ZM22.001 27.725C21.0665 27.725 20.1529 27.4479 19.3759 26.9287C18.5989 26.4095 17.9933 25.6716 17.6356 24.8082C17.278 23.9448 17.1845 22.9948 17.3668 22.0782C17.5491 21.1616 17.9991 20.3197 18.6599 19.6589C19.3207 18.9981 20.1626 18.5481 21.0792 18.3658C21.9957 18.1835 22.9458 18.277 23.8092 18.6347C24.6725 18.9923 25.4105 19.5979 25.9297 20.3749C26.4489 21.152 26.726 22.0655 26.726 23C26.7245 24.2527 26.2263 25.4537 25.3405 26.3395C24.4547 27.2253 23.2537 27.7236 22.001 27.725V27.725Z"
                        fill="black"/>
                </svg>
            </a>
            
            
            <svg
                class="cursor-pointer"
                width="45"
                height="45"
                viewBox="0 0 45 45"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="downloadDocument"
            >
                <rect x="0.48913" y="0.48913" width="44.0217" height="44.0217" rx="22.0109" stroke="black"
                      stroke-opacity="0.2" stroke-width="0.978261"/>
                <path
                    d="M28.4066 16.6504H23.2879V24.1991L25.8812 21.6062C26.0301 21.4648 26.2284 21.3871 26.4337 21.3897C26.6391 21.3923 26.8353 21.4751 26.9805 21.6203C27.1257 21.7655 27.2084 21.9617 27.2111 22.1671C27.2137 22.3724 27.136 22.5707 26.9946 22.7196L23.0571 26.6571C22.9094 26.8046 22.7092 26.8875 22.5004 26.8875C22.2916 26.8875 22.0914 26.8046 21.9437 26.6571L18.0062 22.7196C17.8648 22.5707 17.7871 22.3724 17.7897 22.1671C17.7923 21.9617 17.8751 21.7655 18.0203 21.6203C18.1655 21.4751 18.3617 21.3923 18.5671 21.3897C18.7724 21.3871 18.9707 21.4648 19.1196 21.6062L21.7129 24.1991V16.6504H16.5941C15.8634 16.6512 15.1628 16.9418 14.646 17.4585C14.1293 17.9753 13.8387 18.6759 13.8379 19.4066V29.6441C13.8387 30.3749 14.1293 31.0755 14.646 31.5922C15.1628 32.109 15.8634 32.3996 16.5941 32.4004H28.4066C29.1374 32.3996 29.838 32.109 30.3547 31.5922C30.8715 31.0755 31.1621 30.3749 31.1629 29.6441V19.4066C31.1621 18.6759 30.8715 17.9753 30.3547 17.4585C29.838 16.9418 29.1374 16.6512 28.4066 16.6504Z"
                    fill="black"/>
                <path
                    d="M23.2879 11.1381C23.2879 10.9292 23.2049 10.7289 23.0572 10.5812C22.9096 10.4336 22.7092 10.3506 22.5004 10.3506C22.2915 10.3506 22.0912 10.4336 21.9435 10.5812C21.7959 10.7289 21.7129 10.9292 21.7129 11.1381V16.6506H23.2879V11.1381Z"
                    fill="black"/>
            </svg>
        </div>
    </div>
    
    <MetaDefault
        title="Программа"
    />
</template>

<script setup lang="ts">
import MetaDefault from "@/components/MetaDefault.vue";

function downloadDocument() {
    // URL PDF документа
    const pdfUrl = `/documents/1.pdf`;
    
    // Создание временной ссылки
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'Программа дополнительного образования.pdf'; // Имя файла при сохранении
    
    // Добавление ссылки в DOM
    document.body.appendChild(link);
    
    // Программный клик по ссылке
    link.click();
    
    // Удаление ссылки из DOM
    document.body.removeChild(link);
}

</script>

<style scoped>

</style>