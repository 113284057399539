<template>
    <div
        class="burger-button"
    >
        <div
            class="hamburger hamburger--squeeze"
            :class="[vModel ? 'is-active' : '']"
            @click="changeVModel"
        >
            <div class="hamburger-box">
                <div
                    class="hamburger-inner"
                    :style="{
                        backgroundColor: burgerColor,
                    }"
                />
            </div>
        </div>
    </div>
</template>

<script
    setup
    lang="ts"
>
import { useVModel } from '@/utils/useVModel'

interface Props {
    modelValue: boolean,
    burgerColor?: 'white' | 'black',
}

const props = withDefaults(
    defineProps<Props>(),
    {
        modelValue: false,
        burgerColor: 'black',
    }
);

let vModel = useVModel(props, 'modelValue');

const changeVModel = () => {
    vModel.value = !vModel.value;
}
</script>

